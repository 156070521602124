<template>
    <v-container fluid class="pl-1 pt-0 pb-3 mr-n1" :class="{'mb-3': !isCollapsed && lastIndex !== i, 'mt-3': !isCollapsed && i > 0}">
        <v-row class="mb-1 elevation-1">
            <v-toolbar short flat color="tertiary" class="cursor-pointer"
                @click="collapseItem(isCollapsed ? 'show' : 'hide', i)"
            >
                <v-toolbar-title class="pl-0 ml-n2 text-subtitle-1">
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-if="isCollapsed" v-text="'mdi-unfold-more-horizontal'" />
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-else v-text="'mdi-unfold-less-horizontal'" />
                    <span
                        class="v-toolbar-title-text pl-2"
                        >{{ $t('vvtProcessEditor.recipients.external.headlineItem') }} 
                        {{ i + 1 }}
                        <template v-if="groupName">: {{ groupName }}</template>
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip v-if="i > 0" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('up')"
                        >
                            <v-icon>keyboard_arrow_up</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveUp'" />
                </v-tooltip>
                <v-tooltip v-if="i < lastIndex" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('down')"
                        >
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveDown'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="primary"
                            @click.stop="commentMode = true"
                            v-on="on"
                            small
                            fab
                            depressed
                            :outlined="!hasComments"
                            :disabled="!hasComments && !canEdit"
                        >
                            <v-icon
                                small
                                :color="hasComments ? 'white' : 'primary'"
                                >mdi-comment-outline</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'comments.buttonLabel'"></span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            class="mx-2"
                            color="success"
                            @click.stop="duplicateItem()"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>js-icon-copy</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'duplicate'" />
                </v-tooltip>
                <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            @click.stop="displayRemoveItemConfirmation()"
                            class="mx-2"
                            color="error"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>clear</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'remove'" />
                </v-tooltip>
            </v-toolbar>
        </v-row>
        <v-row
            v-show="!isCollapsed"
            style="margin-top:-16px;"
            class="tertiary px-2 mb-1 fill-height elevation-1"
            align="stretch"
            justify="space-around"
        >
            <v-col cols="12" class="py-0">
                <v-card-title
                    class="pl-0 pb-0 text-subtitle-1"
                    v-t="'vvtProcessEditor.recipients.external.company.headline'"
                />
            </v-col>
            <v-col v-if="getCompanyGroupSettings('useVendor')" cols="12" md="6">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit || get('leaMaster')"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="vendorItems"
                    v-model="vendorModel"
                    fieldName="externalRecipient"
                    childFieldName="vendor"
                    :label="$t('vvtProcessEditor.recipients.vendor.label')"
                    :hint="$t('vvtProcessEditor.recipients.vendor.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.vendor.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    item-text="name"
                    clearable
                    @input="loadVendorDataConfirmation"
                    :loading="$wait.is('fetch vendor')"
                    :prepend-inner-icon="vendorModel ? 'mdi-sync' : null"
                    @click:prepend-inner="loadVendorDataConfirmation"
                />
            </v-col>
            <v-col cols="12" :md="getCompanyGroupSettings('useVendor') ? 6 : 12">
                <LeaTextField
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    fieldName="externalRecipient"
                    childFieldName="company"
                    :label="$t('vvtProcessEditor.recipients.external.company.label')"
                    :hint="$t('vvtProcessEditor.recipients.external.company.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.external.company.helpMode')"
                    v-model="companyModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <LeaDropdown
                    :type="$getDropdownTypeFor('externalRecipientCategory', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                    return-object
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="filteredTypeOfExternalRecipientItems"
                    v-model="typeOfRecipientModel"
                    fieldName="externalRecipient"
                    childFieldName="typeOfRecipient"
                    :label="$t('vvtProcessEditor.recipients.external.typeOfRecipient.label')"
                    :hint="$t('vvtProcessEditor.recipients.external.typeOfRecipient.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.external.typeOfRecipient.helpMode')"
                    clearable
                    :freetext-enabled-hint="$t('createItemHintDefault')"
                    disable-clear-input-on-change
                    disable-clear-input-on-blur
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="$t('processingTypeItems')"
                    v-model="processingTypeModel"
                    fieldName="externalRecipient"
                    childFieldName="processingType"
                    :label="$t('vvtProcessEditor.recipients.processingType.label')"
                    :hint="$t('vvtProcessEditor.recipients.processingType.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.processingType.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    :item-value="function(e) {
                        return e.key
                    }"
                    item-text="title"
                    clearable
                />
            </v-col>
            <v-col cols="12" v-if="processingTypeModel === 'jointControl'">
                <RecipientJointControllerAddress
                    :disabled="!canEdit"
                    :i="i"
                    :rowId="rowId"
                    :formScope="formScope"
                    v-model="jointControllerAddress"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" :md="appropriateSafeguardRequired ? 6 : 12">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="countryItems"
                    v-model="countryModel"
                    fieldName="externalRecipient"
                    childFieldName="country"
                    :label="$t('vvtProcessEditor.recipients.external.country.label')"
                    :hint="$t('vvtProcessEditor.recipients.external.country.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.external.country.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    :item-value="function(e) {
                        return e.countryCode
                    }"
                    item-text="countryName"
                    clearable
                />
            </v-col>
            <v-col cols="12" md="6" v-if="appropriateSafeguardRequired">
                <LeaDropdown
                    :type="$getDropdownTypeFor('appropriateSafeguards', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                    return-object
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="appropriateSafeguardsItems"
                    v-model="appropriateSafeguardsModel"
                    fieldName="externalRecipient"
                    childFieldName="appropriateSafeguards"
                    :label="$t('vvtProcessEditor.recipients.appropriateSafeguards.label')"
                    :hint="$t('vvtProcessEditor.recipients.appropriateSafeguards.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.appropriateSafeguards.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    clearable
                    :delimiters="[',']"
                    :freetext-enabled-hint="$t('createItemHintComma')"
                    multiple
                    chips
                    deletableChips
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.groupOfPersons.headline') }}
            </v-col>
            <v-col cols="12">
                <BaseInputMultiOption
                    :disabled="!canEdit"
                    :items="filteredGroupOfPersonItems"
                    v-model="groupsOfPersonsModel"
                    :rowId="rowId"
                    :formScope="formScope"
                    fieldName="externalRecipient"
                    childFieldName="groupOfPersons"
                    :label="$t('vvtProcessEditor.recipients.groupOfPersons.label')"
                    :hint="$t('vvtProcessEditor.recipients.groupOfPersons.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.groupOfPersons.helpMode')"
                    :noItemsMessage="$t('vvtProcessEditor.groupOfPerson.groupOfPersons.noItemsMessage')"
                    :step="5"
                    multiple
                    @help-mode="$emit('help-mode', $event)"
                    @input="createRecipientPersonalData($event)"
                    :noDataText="$t('vvtProcessEditor.recipients.groupOfPersons.noDataText')"
                />
            </v-col>
            <v-col cols="12" v-if="usePersonalData && recipient.groupsOfPersons && recipient.groupsOfPersons.length">
                <RecipientPersonalData
                    :disabled="!canEdit"
                    :i="i"
                    :rowId="rowId"
                    :groupsOfPersonItems="groupsOfPersonGroupItems"
                    :personalDataItems="personalDataItems"
                    :groupsOfPersonSelectedItems="groupsOfPersonSelectedItems"
                    v-model="personalDataModel"
                    fieldName="externalRecipient"
                    childFieldName="personalData"
                    :label="$t('vvtProcessEditor.recipients.personalData.label')"
                    :dialogTitle="$t('vvtProcessEditor.recipients.personalData.dialogTitle')"
                    :dialogDescription="$t('vvtProcessEditor.recipients.personalData.dialogDescription')"
                />
            </v-col>
            <v-col cols="12" v-if="useDataCategories && recipient.groupsOfPersons && recipient.groupsOfPersons.length">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="usedPersonalDataCategoryItems"
                    v-model="personalDataCategoryModel"
                    fieldName="externalRecipient"
                    childFieldName="personalDataCategories"
                    :label="$t('vvtProcessEditor.recipients.personalDataCategories.label')"
                    :hint="$t('vvtProcessEditor.recipients.personalDataCategories.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.personalDataCategories.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    clearable
                    chips
                    deletableChips
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.purpose.headline') }}
            </v-col>
            <v-col cols="12" md="9">
                <LeaTextArea
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :rowId="rowId"
                    fieldName="externalRecipient"
                    childFieldName="purpose"
                    :label="$t('vvtProcessEditor.recipients.purpose.label')"
                    :hint="$t('vvtProcessEditor.recipients.purpose.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.purpose.helpMode')"
                    :create="create"
                    v-model="purposeModel"
                    rows="2"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" md="3">
                <LeaSwitch
                    :disabled="!canEdit"
                    v-model="dataTransferSecurityModel"
                    :formScope="formScope"
                    :step="5"
                    :rowId="rowId"
                    fieldName="externalRecipient"
                    childFieldName="security"
                    :title="$t('vvtProcessEditor.recipients.security.label')"
                    :hint="$t('vvtProcessEditor.recipients.security.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.security.helpMode')"
                    :label-on="$t('yes')"
                    :label-off="$t('no')"
                    :create="create"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.subcontractors.headline') }}
            </v-col>
            <v-col cols="12">
                <LeaTextArea
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :rowId="rowId"
                    fieldName="externalRecipient"
                    childFieldName="subcontractors"
                    :label="$t('vvtProcessEditor.recipients.subcontractors.label')"
                    :hint="$t('vvtProcessEditor.recipients.subcontractors.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.subcontractors.helpMode')"
                    :create="create"
                    v-model="subcontractorsModel"
                    rows="2"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.avvUpload.headline') }}
            </v-col>
            <v-col cols="12">
                <LeaTextField
                    :disabled="!canEdit"
                    :formScope="formScope"
                    fieldName="externalRecipient"
                    childFieldName="lcm"
                    :label="$t('vvtProcessEditor.recipients.lcm.label')"
                    :hint="$t('vvtProcessEditor.recipients.lcm.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.lcm.helpMode')"
                    :step="5"
                    v-model="lcmModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12">
                <RecipientAvvUpload
                    :disabled="!canEdit"
                    :i="i"
                    :rowId="rowId"
                    v-model="avvUploadModel"
                    fieldName="externalRecipient"
                    childFieldName="avvUpload"
                    :label="$t('vvtProcessEditor.recipients.avvUpload.label')"
                    :hint="$t('vvtProcessEditor.recipients.avvUpload.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.avvUpload.helpMode')"
                    :removeHint="$t('remove')"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12">
                <BaseInputMultiOption
                    :disabled="!canEdit"
                    :items="legalityItems"
                    v-model="legalityModel"
                    fieldName="externalRecipient"
                    childFieldName="legality"
                    :label="$t('vvtProcessEditor.recipients.legality.label')"
                    :hint="$t('vvtProcessEditor.recipients.legality.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.legality.helpMode')"
                    :itemsHelpMode="$t('vvtProcessEditor.recipients.legality.items')"
                    :rowId="rowId"
                    :formScope="formScope"
                    :step="5"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
        </v-row>
        <v-row v-show="!isCollapsed" class="elevation-1 mt-0 mb-1">
            <v-col cols="12" class="pa-0">  
                <v-expansion-panels
                    v-model="expertAreaModel"
                    class="mr-3"
                    flat
                >
                    <v-expansion-panel hideActions>
                        <v-expansion-panel-header
                            class="text-subtitle-1 primary--text px-4"
                            color="tertiary"
                            :expand-icon="null"
                            :class="{
                                'font-weight-bold': expertAreaModel === 0,
                                'font-weight-medium': expertAreaModel !== 0,
                            }"
                        >
                            <div>
                                <v-icon
                                    color="primary"
                                    class="pr-2"
                                    :class="{ 'rotate-90': expertAreaModel === 0 }"
                                    >keyboard_arrow_right</v-icon
                                >
                                {{ $t('vvtProcessEditor.recipients.expertArea') }}
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="tertiary">
                            <LegitimateInterest
                                v-if="hasLegitimateInterest"
                                :disabled="!canEdit"
                                :formScope="formScope"
                                componentScope="externalRecipients"
                                :i="i"
                                :rowId="rowId"
                                v-model="legitimateInterestModel"
                                validationName="externalRecipientLegitimateInterest"
                                groupName="externalRecipient"
                                stepNum="5"
                                :currentUiLanguage="currentUiLanguage"
                                :ref="formScope + '_externalRecipient_legitimate_interest_' + rowId"
                                @help-mode="$emit('help-mode', $event)"
                                fieldName="externalRecipient"
                                childFieldName="legitimateInterest"
                            />
                            <v-row
                                class="pa-2 pt-6 pb-0 mb-1"
                                justify="space-around"
                            >
                                <v-col cols="12" v-if="hasLegalObligation">
                                    <LeaDropdown
                                        type="autocomplete"
                                        :disabled="!canEdit"
                                        v-model="nationalLegalObligationModel"
                                        :items="recipientNationalLegalObligationItems"
                                        :formScope="formScope"
                                        :step="5"
                                        :rowId="rowId"
                                        clearable
                                        fieldName="externalRecipient"
                                        childFieldName="nationalLegalObligation"
                                        :label="$t('vvtProcessEditor.recipients.nationalLegalObligation.label')"
                                        :hint="$t('vvtProcessEditor.recipients.nationalLegalObligation.hint')"
                                        :helpModeText="$t('vvtProcessEditor.recipients.nationalLegalObligation.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                    />
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaDropdown
                                        type="autocomplete"
                                        :disabled="!canEdit"
                                        v-model="flexibilityClauseModel"
                                        :items="flexibilityClauseItems"
                                        :formScope="formScope"
                                        :step="5"
                                        :rowId="rowId"
                                        fieldName="externalRecipient"
                                        childFieldName="flexibilityClause"
                                        :label="$t('vvtProcessEditor.recipients.flexibilityClause.label')"
                                        :hint="$t('vvtProcessEditor.recipients.flexibilityClause.hint')"
                                        :helpModeText="$t('vvtProcessEditor.recipients.flexibilityClause.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                        multiple
                                        chips
                                        deletableChips
                                        enable-select-all
                                    >
                                        <template v-slot:item="{ parent, item }" subheader>
                                            <template v-if="typeof item !== 'object'">
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template>

                                            <template v-else>
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        color="primary"
                                                        :input-value="parent.hasItem(item)"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-spacer />
                                                <v-list-item-action>
                                                    <v-tooltip left color="primary">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" color="primary"
                                                                >info</v-icon
                                                            >
                                                        </template>
                                                        <span
                                                            ><strong>{{ item.title }}:</strong>
                                                            {{ item.description }}</span
                                                        >
                                                    </v-tooltip>
                                                </v-list-item-action>
                                            </template>
                                        </template>
                                        <template v-slot:selection="{ item, parent, selected }">
                                            <v-tooltip v-if="item === Object(item)" bottom color="primary" z-index="100">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip
                                                        :input-value="selected"
                                                        class="v-chip--removable"
                                                        :class="{'v-chip--disabled': !canEdit}"
                                                        v-on="on"
                                                    >
                                                        {{ item.title }}
                                                        <v-btn v-if="canEdit" icon @click="parent.selectItem(item)" class="ml-3 mr-n3 v-chip__close">
                                                            <v-icon size="18"
                                                                >mdi-close-circle</v-icon
                                                            >
                                                        </v-btn>
                                                    </v-chip>
                                                </template>
                                                <span><strong>{{ item.title }}<span v-if="item.description">:</span></strong>{{ item.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </LeaDropdown>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaDropdown
                                        v-if="countryCode"
                                        :type="$getDropdownTypeFor('nationalDataProtectionLaw', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                                        return-object
                                        :disabled="!canEdit"
                                        v-model="nationalDataProtectionLawModel"
                                        :items="filteredNationalDataProtectionLawItems"
                                        :formScope="formScope"
                                        :step="5"
                                        :rowId="rowId"
                                        fieldName="externalRecipient"
                                        childFieldName="nationalDataProtectionLaw"
                                        :label="$t('vvtProcessEditor.recipients.nationalDataProtectionLaw.label')"
                                        :hint="$t('vvtProcessEditor.recipients.nationalDataProtectionLaw.hint')"
                                        :helpModeText="$t('vvtProcessEditor.recipients.nationalDataProtectionLaw.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                        multiple
                                        chips
                                        deletableChips
                                        enable-select-all
                                        :delimiters="[',']"
                                        :freetext-enabled-hint="$t('createItemHintComma')"
                                        disable-clear-input-on-change
                                        disable-clear-input-on-blur
                                    />
                                    <v-card
                                        v-else
                                        align-start
                                        color="white"
                                        class="default"
                                        height="100%"
                                        outlined
                                    >
                                        <v-card-text v-t="'vvtProcessEditor.recipients.nationalDataProtectionLaw.creationNote'" />
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <BaseConfirmationDialog
            v-model="baseConfirmation"
            :title="baseConfirmationContent.title"
            :text="baseConfirmationContent.text"
            :confirmationText="baseConfirmationContent.confirmationText"
            :cancelText="baseConfirmationContent.cancelText"
            @cancel="baseConfirmation = false"
            @confirm="baseConfirmationContent.confirmFn()"
        />
        <CommentsDialog
            v-model="commentMode"
            :generalComments="generalComments"
            :internalComments="internalComments"
            @data="setCommentsToModel($event)"
            :disabled="!canEdit"
        />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { v4 as uuid } from 'uuid';
import ModelMixin from '@/components/vvt/ModelMixin';
import LeaTextField from '@/components/Input/LeaTextField';
import LeaDropdown from '@/components/Input/LeaDropdown';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaSwitch from '@/components/Input/LeaSwitch';
import RecipientJointControllerAddress from '../RecipientJointControllerAddress.vue';
import PersonalDataMixin from '../PersonalDataMixin.js';
import RecipientPersonalData from '../RecipientPersonalData.vue';
import RecipientAvvUpload from '../RecipientAvvUpload.vue';
import LegitimateInterest from '@/components/LegitimateInterest';
import CommentsDialog from '@/components/vvt/ProcessEditor/CommentsDialog.vue';

export default {
    name: 'ExternalRecipientItem',
    mixins: [ModelMixin, PersonalDataMixin],
    components: {
        LeaTextField,
        LeaDropdown,
        LeaTextArea,
        LeaSwitch,
        RecipientJointControllerAddress,
        RecipientPersonalData,
        RecipientAvvUpload,
        LegitimateInterest,
        CommentsDialog,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        i: {
            type: Number,
            default: null,
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        lastIndex: {
            type: Number,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        groupsOfPersonSelectedItems: {
            type: Array,
            default: () => []
        },
        countryItems: {
            type: Array,
            default: () => []
        },
        groupsOfPersonGroupItems: {
            type: Array,
            default: () => []
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        editorMode: {
            type: String,
            default: null,
        },
        currentUiLanguage: {
            type: String,
            default: null,
        },
        forcedLanguage: {
            type: String,
            default: null,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            highlightCollapseButton: false,
            baseConfirmation: false,
            baseConfirmationContent: {
                title: null,
                text: null,
                confirmationText: null,
                cancelText: null,
                confirmFn: () => {}
            },
            expertAreaModel:
                this.hasLegitimateInterest || this.hasLegalObligation
                    ? 0
                    : null,
            appropriateSafeguardRequired: false,
            commentMode: false,
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getChild: 'processingActivityModel/getChildProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            usedPersonalDataCategoryItems: 'processingActivityModel/getUsedPersonalDataCategoryItems',
            vendorItems: 'vendor/getItems',
            recipientNationalLegalObligationItems: 'recipientNationalLegalObligation/getItems',
            flexibilityClauseItems: 'flexibilityClause/getCategoriesWithItems',
            nationalDataProtectionLawItems: 'nationalDataProtectionLaw/getItems',
            personalDataItems: 'personalData/getItems',
            typeOfExternalRecipientItems: 'externalRecipientCategories/getItems',
            getAllChildModelItems: 'processingActivityModel/getAllChildModelItems',
            legalityItems: 'legality/getItems',
            appropriateSafeguardsItems: 'appropriateSafeguards/getItems',
        }),
        filteredTypeOfExternalRecipientItems() {
            return this.typeOfExternalRecipientItems.filter(
                x =>
                    !x.isHidden ||
                    this.getAllChildModelItems({
                        prop: 'externalRecipients',
                        childProp: 'typeOfRecipient',
                        childParam: 'id',
                    }).includes(x.id)
            );
        },
        recipient: {
            get() {
                return this.getChild({
                    property: 'externalRecipients',
                    child: this.i,
                });
            },
            set(val) {
                this.set({
                    property: 'externalRecipients',
                    child: this.i,
                    data: val,
                });
            },
        },
        jointControllerAddress: {
            get() {
                return this.recipient.jointControllerAddress;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ jointControllerAddress: val },
                };
                this.recipient = merged;
            },
        },
        companyModel: {
            get() {
                return this.recipient.company.title;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ company: { id: null, title: val } },
                };
                this.recipient = merged;
            },
        },
        groupName() {
            if (this.companyModel === '') {
                return null;
            }
            return this.companyModel;
        },
        vendorModel: {
            get() {
                return this.recipient.vendor;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ vendor: val },
                };
                this.recipient = merged;
            },
        },
        typeOfRecipientModel: {
            get() {
                return this.getValue(this.filteredTypeOfExternalRecipientItems, this.recipient.typeOfRecipient);
            },
            set(val) {
                const data = val ? this.setValue(
                    this.filteredTypeOfExternalRecipientItems,
                    val,
                    null,
                    null,
                    'externalRecipientCategories/unshiftItems'
                ) : null;
                const merged = {
                    ...this.recipient,
                    ...{ typeOfRecipient: data },
                };
                this.recipient = merged;
            },
        },
        processingTypeModel: {
            get() {
                return this.recipient.processingType;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ processingType: val },
                };
                this.recipient = merged;
            },
        },
        countryModel: {
            get() {
                return this.recipient.country;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ country: val } };
                this.recipient = merged;
                this.checkAppropriateSafeguardRequiredState();
            },
        },
        appropriateSafeguardsModel: {
            get() {
                return this.getValue(this.appropriateSafeguardsItems, this.recipient.appropriateSafeguards);
            },
            set(val) {
                const data = this.setValue(
                    this.appropriateSafeguardsItems,
                    val,
                    null,
                    null,
                    'appropriateSafeguards/unshiftItems'
                );
                const merged = {
                    ...this.recipient,
                    ...{ appropriateSafeguards: data },
                };
                this.recipient = merged;
            },
        },
        filteredGroupOfPersonItems() {
            const items = this.get('groupsOfPersons').map(x => {
                const itemId = x.group.id || x.group.clientId;
                const rowId = x.id;
                const rowClientId = x.clientId;
                const originalItem = itemId ? this.groupsOfPersonGroupItems.find(
                    x => x.id === itemId || x.clientId === itemId
                ) : {title: this.$t('N/A')};
                const item = {
                    ...originalItem,
                    ...{ id: rowId, clientId: rowClientId },
                };
                return item;
            });
            return JSON.parse(JSON.stringify(items));
        },
        groupsOfPersonsModel: {
            get() {
                return this.recipient.groupsOfPersons;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ groupsOfPersons: val },
                };
                this.recipient = merged;
            },
        },
        personalDataModel: {
            get() {
                return this.recipient.personalData;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ personalData: val } };
                this.recipient = merged;
            },
        },
        personalDataCategoryModel: {
            get() {
                return this.recipient.personalDataCategory;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ personalDataCategory: val },
                };
                this.recipient = merged;
            },
        },
        purposeModel: {
            get() {
                return this.recipient.purpose && this.recipient.purpose.title ? this.recipient.purpose.title : null;
            },
            set(val) {
                let data = {...this.recipient.purpose, ...{title: val}};
                const merged = { ...this.recipient, ...{ purpose: data } };
                this.recipient = merged;
            },
        },
        dataTransferSecurityModel: {
            get() {
                return this.recipient.dataTransferSecurity;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ dataTransferSecurity: val },
                };
                this.recipient = merged;
            },
        },
        subcontractorsModel: {
            get() {
                return this.recipient.subcontractors;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ subcontractors: val },
                };
                this.recipient = merged;
            },
        },
        lcmModel: {
            get() {
                return this.recipient.lcm;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ lcm: val } };
                this.recipient = merged;
            },
        },
        avvUploadModel: {
            get() {
                return this.recipient.avvUpload;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ avvUpload: val } };
                this.recipient = merged;
            },
        },
        legalityModel: {
            get() {
                return this.recipient.legality;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ legality: val } };
                this.recipient = merged;

                if (this.hasLegitimateInterest || this.hasLegalObligation) {
                    this.openExpertAreaModel();
                }
            },
        },
        nationalLegalObligationModel: {
            get() {
                return this.getValue(this.recipientNationalLegalObligationItems, this.recipient.nationalLegalObligation);
            },
            set(val) {
                if (typeof val === 'object' && val !== null && val.id) {
                    val = val.id;
                } else if (typeof val === 'string') {
                    val = val.replace(/^\s+|\s+$/g, '').replace(/\s\s/g, ' ');
                }
                const merged = {
                    ...this.recipient,
                    ...{ nationalLegalObligation: val },
                };
                this.recipient = merged;
            },
        },
        legitimateInterestModel: {
            get() {
                return this.recipient.legitimateInterest;
            },
            set(val) {
                this.setLegitimateInterest({
                    index: this.i,
                    data: val,
                });
            },
        },
        flexibilityClauseModel: {
            get() {
                return this.getValue(this.flexibilityClauseItems, this.recipient.flexibilityClause);
            },
            set(val) {
                const data = this.setValue(this.flexibilityClauseItems, val);
                const merged = {
                    ...this.recipient,
                    ...{ flexibilityClause: data },
                };
                this.recipient = merged;
            },
        },
        filteredNationalDataProtectionLawItems () {
            return this.nationalDataProtectionLawItems.filter(
                x => !x.country || x.country === this.countryCode.toUpperCase()
            );
        },
        nationalDataProtectionLawModel: {
            get() {
                return this.getValue(this.nationalDataProtectionLawItems, this.recipient.nationalDataProtectionLaw);
            },
            set(val) {
                const data = this.setValue(
                    this.nationalDataProtectionLawItems,
                    val,
                    null,
                    {
                        country: this.countryCode
                            ? this.countryCode.toUpperCase()
                            : null,
                    },
                    'nationalDataProtectionLaw/unshiftItems'
                );
                const merged = {
                    ...this.recipient,
                    ...{ nationalDataProtectionLaw: data },
                };
                this.recipient = merged;
            },
        },
        generalComments: {
            get() {
                return this.recipient.generalComments;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ generalComments: val },
                };
                this.recipient = merged;
            },
        },
        internalComments: {
            get() {
                return this.recipient.internalComments;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ internalComments: val },
                };
                this.recipient = merged;
            },
        },
        hasComments() {
            return (
                !!(
                    (this.generalComments &&
                        this.generalComments.trim().length) ||
                    (this.internalComments &&
                        this.internalComments.trim().length)
                ) === true
            );
        },
        hasLegitimateInterest() {
            const legalityItem = this.legalityItems.find(
                x => x.id === this.recipient.legality
            );
            return legalityItem && legalityItem.legitimateInterest
                ? legalityItem.legitimateInterest
                : false;
        },
        hasLegalObligation() {
            const legalityItem = this.legalityItems.find(
                x => x.id === this.recipient.legality
            );
            return legalityItem && legalityItem.legalObligation
                ? legalityItem.legalObligation
                : false;
        },
        useDataCategories () {
            if (this.hasPersonalDataCategories) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataCategories');
        },
        usePersonalData () {
            if (this.hasPersonalData) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataFields');
        },
        hasPersonalData() {
            return this.get('groupsOfPersons').some(x => x.personalData && x.personalData.length);
        },
        hasPersonalDataCategories() {
            return this.get('groupsOfPersons').some(x => x.personalDataCategory && x.personalDataCategory.length);
        },
        recordLanguage() {
            return this.get('language') ? this.get('language') : this.currentUiLanguage;
        }
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setChildProperty',
            remove: 'processingActivityModel/removeExternalRecipient',
            add: 'processingActivityModel/addExternalRecipient',
            move: 'processingActivityModel/moveExternalRecipient',
            addPersonalData: 'processingActivityModel/addExternalRecipientPersonalData',
            addLegitimateInterest: 'processingActivityModel/addExternalRecipientLegitimateInterest',
            setLegitimateInterest: 'processingActivityModel/setExternalRecipientLegitimateInterest',
            fetchVendor: 'vendor/fetchById',
        }),
        loadVendorDataConfirmation() {
            if(this.vendorModel && this.vendorModel > 0) {
                this.baseConfirmationContent.title = this.$t('vvtProcessEditor.recipients.vendor.replaceConfirmation.title');
                this.baseConfirmationContent.text = this.$t('vvtProcessEditor.recipients.vendor.replaceConfirmation.text');
                this.baseConfirmationContent.confirmationText = this.$t('vvtProcessEditor.recipients.vendor.replaceConfirmation.confirmationText');
                this.baseConfirmationContent.cancelText = this.$t('vvtProcessEditor.recipients.vendor.replaceConfirmation.cancelText');
                this.baseConfirmationContent.confirmFn = this.loadVendorData;
                this.baseConfirmation = true;
            }
        },
        loadVendorData() {
            this.$wait.start('fetch vendor');
            this.fetchVendor({
                id: this.vendorModel,
                forcedLanguage: this.forcedLanguage
            }).then((e) => {
                this.replaceVendorData(e.data);
                this.$wait.end('fetch vendor');
                this.baseConfirmation = false;
            });
        },
        getUsedGOPData(vendor) {
            let gopItemIds = [];
            let recipientPersonalDataItems = [];
            this.get('groupsOfPersons').forEach((gopItem) => {
                if(gopItem.group && gopItem.group.id && vendor.groupsOfPersons.includes(gopItem.group.id)) {

                    gopItemIds.push(gopItem.id || gopItem.clientId);

                    let recipientPersonalData = {
                        id: null,
                        clientId: uuid(),
                        groupId: gopItem.id || gopItem.clientId,
                        personalData: []
                    };
                    
                    gopItem.personalData.forEach((pdId) => {
                        if(vendor.personalData.includes(pdId)) {
                            recipientPersonalData.personalData.push(pdId);
                        }
                    });
                    recipientPersonalDataItems.push(recipientPersonalData);
                }
            });
            return {
                gopItemIds,
                recipientPersonalDataItems
            };
        },
        replaceVendorData(vendor) {
            let usedGOPData = this.getUsedGOPData(vendor);
            let usedGOPItemIds = usedGOPData.gopItemIds;
            let recipientPersonalDataItems = usedGOPData.recipientPersonalDataItems;

            let usedPersonalDataCategoryItemIds = vendor.personalDataCategory.filter(y => this.usedPersonalDataCategoryItems.filter(x => x.id === y).map(x => x.id));
            let vendorTrans = vendor.translations.find(x => x.locale === this.recordLanguage);
            let vendorPurpose = vendorTrans ? vendorTrans.purpose : null;
            let vendorLcm = vendorTrans ? vendorTrans.lcm : null;
            let vendorSubcontractors = vendorTrans ? vendorTrans.subcontractors : null;

            let vendorLegiTrans = vendor.legitimateInterest ? vendor.legitimateInterest.translations.find(x => x.locale === this.recordLanguage) : null;
            let vendorLegitimateInterestTitle = vendorLegiTrans ? vendorLegiTrans.title : null;
            let vendorLegitimateInterestResponsiblePerson = vendorLegiTrans ? vendorLegiTrans.interestResponsiblePerson : null;
            let vendorLegitimateInterestPersonConcerned = vendorLegiTrans ? vendorLegiTrans.interestPersonConcerned : null;
            let vendorLegitimateInterestNecessity = vendorLegiTrans ? vendorLegiTrans.necessity : null;
            let vendorLegitimateInterestBalancingOfInterests = vendorLegiTrans ? vendorLegiTrans.balancingOfInterests : null;

            let typeOfExternalRecipientItem = this.filteredTypeOfExternalRecipientItems.find(x => x.id === vendor.typeOfRecipient);
            let typeOfExternalRecipientTitle = typeOfExternalRecipientItem ? typeOfExternalRecipientItem.title : null;

            let vendorCountryCode = vendor.address && vendor.address.country ? vendor.address.country : null;
            let vendorNationalDataProtectionLaw = this.countryCode === vendorCountryCode ? vendor.nationalDataProtectionLaw : [];

            let vendorDpaEnclosures = vendor.dpaEnclosure.map(x => {
                x.uiDuplicate = true;
                return x;
            });

            const merged = {
                ...this.recipient,
                ...{ 
                    company: { title: vendor.name },
                    country: vendorCountryCode,
                    jointControllerAddress: {
                        id: this.recipient.jointControllerAddress && this.recipient.jointControllerAddress.id ? this.recipient.jointControllerAddress.id : null,
                        city: vendor.address && vendor.address.city ? vendor.address.city : null,
                        postcode: vendor.address && vendor.address.postcode ? vendor.address.postcode : null,
                        street: vendor.address && vendor.address.street ? vendor.address.street : null,
                    },
                    typeOfRecipient: {
                        id: vendor.typeOfRecipient,
                        title: typeOfExternalRecipientTitle
                    },
                    processingType: vendor.processingType,
                    groupsOfPersons: usedGOPItemIds,
                    personalData: recipientPersonalDataItems,
                    personalDataCategory: usedPersonalDataCategoryItemIds,
                    appropriateSafeguards: vendor.appropriateSafeguards,
                    purpose: {
                        title: vendorPurpose
                    },
                    dataTransferSecurity: vendor.dataTransferSecurity,
                    subcontractors: vendorSubcontractors,
                    lcm: vendorLcm,
                    avvUpload: vendorDpaEnclosures,
                    legality: vendor.legality,
                    nationalLegalObligation: vendor.nationalLegalObligation,
                    flexibilityClause: vendor.flexibilityClause,
                    nationalDataProtectionLaw: vendorNationalDataProtectionLaw,
                    legitimateInterest: {
                        id: vendor.legitimateInterest ? vendor.legitimateInterest.id : null,
                        originalId: vendor.legitimateInterest ? vendor.legitimateInterest.originalId : null,
                        title: vendorLegitimateInterestTitle,
                        interestResponsiblePerson: vendorLegitimateInterestResponsiblePerson,
                        interestPersonConcerned: vendorLegitimateInterestPersonConcerned,
                        necessity: vendorLegitimateInterestNecessity,
                        balancingOfInterests: vendorLegitimateInterestBalancingOfInterests,
                    }
                },
            };
            this.recipient = merged;
        },
        displayRemoveItemConfirmation() {
            this.baseConfirmationContent.title = this.$t('rowItem.removeConfirmation.headline');
            this.baseConfirmationContent.text = this.$t('rowItem.removeConfirmation.text');
            this.baseConfirmationContent.confirmationText = this.$t('rowItem.removeConfirmation.delete');
            this.baseConfirmationContent.cancelText = this.$t('rowItem.removeConfirmation.cancel');
            this.baseConfirmationContent.confirmFn = this.removeItem;
            this.baseConfirmation = true;
        },
        openExpertAreaModel() {
            this.expertAreaModel = 0;
        },
        removeItem() {
            this.remove(this.i).then(this.$emit('collapseAll'));
        },
        duplicateItem() {
            this.add({
                item: this.recipient,
                index: this.i + 1,
            })
                .then(this.$emit('collapseAll'))
                .then(this.collapseItem('show', this.i + 1));
        },
        moveItem(dir) {
            this.move({
                from: this.i,
                to: dir === 'up' ? this.i - 1 : this.i + 1,
            }).then(this.$emit('collapseAll'));
        },
        checkAppropriateSafeguardRequiredState() {
            if (this.recipient.country) {
                const countryCode = this.recipient.country;
                const country = this.countryItems.find(
                    x => x.countryCode === countryCode
                );
                if (country) {
                    if (
                        (!country.eu &&
                            !country.eea &&
                            !country.safeThirdCountry) ||
                        country.countryCode === 'US'
                    ) {
                        this.appropriateSafeguardRequired = true;
                    } else {
                        this.appropriateSafeguardRequired = false;
                    }
                }
            }
        },
        collapseItem(type, index) {
            this.$emit('collapse', {
                type: type,
                index: index,
            });
            this.calculateInputHeights();
        },
        calculateInputHeights() {
            const legitimateInterest = this.$refs[
                this.formScope +
                    '_externalRecipient_legitimate_interest_' +
                    this.rowId
            ];
            if (legitimateInterest) {
                legitimateInterest.calculateInputHeight();
            }
        },
        setCommentsToModel(val) {
            this.generalComments = val.generalComments;
            this.internalComments = val.internalComments;
        },
    },
    created() {
        if (this.recipient.company) {
            this.checkAppropriateSafeguardRequiredState();
        }

        if (!this.recipient.personalData) {
            this.addPersonalData(this.i);
        }

        // create legitimateInterest object if it not exists
        if (!this.recipient.legitimateInterest) {
            this.addLegitimateInterest(this.i);
        }

        if (this.hasLegitimateInterest || this.hasLegalObligation) {
            this.openExpertAreaModel();
        }
    }
};
</script>
