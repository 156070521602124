<template>
    <v-card flat tile color="secondary" class="pb-3">
        <RowHeading
            :headline="$t('vvtProcessEditor.recipients.internal.headline')"
            :subheadline="$t('vvtProcessEditor.recipients.internal.subheadline')"
            :button-text="$t('vvtProcessEditor.recipients.internal.add')"
            :disabled="!canEdit"
            @click:add="addRow"
            expanded
         />
        <div v-if="internalRecipients && internalRecipients.length" class="pl-6">
            <RecipientItem
                v-for="(recipient, i) in internalRecipients"
                :create="create"
                :key="recipient.id || recipient.clientId"
                :rowId="recipient.id || recipient.clientId"
                :formScope="formScope"
                :i="i"
                :lastIndex="internalRecipients.length - 1"
                :departmentItems="departmentCategories"
                :groupsOfPersonGroupItems="groupsOfPersonGroupItems"
                :groupsOfPersonSelectedItems="groupsOfPersonSelectedItems"
                :isCollapsed="collapseStates[i]"
                :canEdit="canEdit"
                @collapse="onCollapse($event)"
                @collapseAll="onCollapseAll()"
                @help-mode="$emit('help-mode', $event)"
            />
        </div>
        <div v-else class="ma-3">
            <v-btn 
                :disabled="!canEdit"
                @click="addRow"
                color="success"
                x-large
                block
                text
                outlined
                class="btn--justify-content-start text-transform--none"><v-icon left>mdi-plus</v-icon>{{ $t('vvtProcessEditor.recipients.internal.addHint') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { kebabCase } from 'lodash';
import RowHeading from '@/components/vvt/ProcessEditor/Row/Heading.vue';
import RecipientItem from './RecipientItem.vue';

export default {
    name: 'InternalRecipientsIndex',
    components: {
        RowHeading,
        RecipientItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            name: kebabCase(this.$options.name),
            collapseStates: [],
        };
    },
    computed: {
        ...mapGetters({
            groupsOfPersonGroupItems: 'groupsOfPersons/getItems',
            get: 'processingActivityModel/getProperty',
            departmentCategories: 'departmentCategories/getItems',
        }),
        internalRecipients() {
            return this.get('internalRecipients');
        },
        groupsOfPersonSelectedItems() {
            return this.get('groupsOfPersons');
        },
        canEdit () {
            return this.get('canEdit');
        },
    },
    methods: {
        addRow() {
            this.$store
                .dispatch('processingActivityModel/addInternalRecipient')
                .then(() => {
                    this.collapseAll();
                    this.$set(this.collapseStates, 0, false);
                });
        },
        onCollapse(event) {
            if (event.type === 'hide') {
                this.$set(this.collapseStates, event.index, true);
            } else {
                this.$set(this.collapseStates, event.index, false);
            }
        },
        onCollapseAll() {
            this.collapseAll();
        },
        collapseAll() {
            for (
                var i = 0, len = this.internalRecipients.length;
                i < len;
                i++
            ) {
                this.$set(this.collapseStates, i, true);
            }
        }
    },
    created() {
        this.collapseAll();
    },
};
</script>
