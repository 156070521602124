<template>
    <v-container fluid class="px-0 pt-0 pb-1">
        <v-row class="px-0 pb-1 fill-height" justify="space-around">
            <v-col cols="12">
                <v-card-title
                    class="pl-0 pb-0 text-subtitle-1"
                    v-t="'vvtProcessEditor.recipients.jointControllerAddress.label'"
                />
            </v-col>
            <v-col cols="12">
                <LeaTextField
                    :disabled="disabled"
                    :formScope="formScope"
                    :step="5"
                    fieldName="externalRecipient"
                    childFieldName="street"
                    :label="$t('vvtProcessEditor.recipients.jointControllerAddress.street.label')"
                    :hint="$t('vvtProcessEditor.recipients.jointControllerAddress.street.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.jointControllerAddress.street.helpMode')"
                    v-model="streetModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <LeaTextField
                    :disabled="disabled"
                    :formScope="formScope"
                    :step="5"
                    fieldName="externalRecipient"
                    childFieldName="postcode"
                    :label="$t('vvtProcessEditor.recipients.jointControllerAddress.postcode.label')"
                    :hint="$t('vvtProcessEditor.recipients.jointControllerAddress.postcode.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.jointControllerAddress.postcode.helpMode')"
                    v-model="postcodeModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <LeaTextField
                    :disabled="disabled"
                    :formScope="formScope"
                    :step="5"
                    fieldName="externalRecipient"
                    childFieldName="city"
                    :label="$t('vvtProcessEditor.recipients.jointControllerAddress.city.label')"
                    :hint="$t('vvtProcessEditor.recipients.jointControllerAddress.city.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.jointControllerAddress.city.helpMode')"
                    v-model="cityModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import LeaTextField from '@/components/Input/LeaTextField';

export default {
    name: 'RecipientJointControllerAddress',
    components: {
        LeaTextField
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        i: { 
            type: Number,
            default: null,
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        formScope: {
            type: String,
            default: null,
        },
        value: {
            type: Object,
            default: () => {
                return {
                    street: null,
                    postcode: null,
                    city: null,
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        streetModel: {
            get() {
                return this.value.street;
            },
            set(val) {
                const merged = { ...this.value, ...{ street: val } };
                this.$emit('input', merged);
            }
        },
        postcodeModel: {
            get() {
                return this.value.postcode;
            },
            set(val) {
                const merged = { ...this.value, ...{ postcode: val } };
                this.$emit('input', merged);
            }
        },
        cityModel: {
            get() {
                return this.value.city;
            },
            set(val) {
                const merged = { ...this.value, ...{ city: val } };
                this.$emit('input', merged);
            }
        },
    },
};
</script>
