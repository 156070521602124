<template>
    <div>
        <InternalRecipients
            :create="create"
            :formScope="formScope"
            class="mb-12"
            @help-mode="$emit('help-mode', $event)"
        />
        <CorporationRecipients
            :create="create"
            :formScope="formScope"
            :editorMode="editorMode"
            :countryCode="countryCode"
            :currentUiLanguage="currentUiLanguage"
            class="mb-12"
            @help-mode="$emit('help-mode', $event)"
        />
        <ExternalRecipients
            :create="create"
            :formScope="formScope"
            :editorMode="editorMode"
            :countryCode="countryCode"
            :currentUiLanguage="currentUiLanguage"
            :forcedLanguage="forcedLanguage"
            @help-mode="$emit('help-mode', $event)"
        />
    </div>
</template>

<script>
import InternalRecipients from './Internal';
import CorporationRecipients from './Corporation';
import ExternalRecipients from './External';
export default {
    name: 'Recipients',
    components: {
        InternalRecipients,
        CorporationRecipients,
        ExternalRecipients,
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        editorMode: {
            type: String,
            default: null,
        },
        currentUiLanguage: {
            type: String,
            default: null,
        },
        forcedLanguage: {
            type: String,
            default: null,
        }
    },
};
</script>
