<template>
    <form
        @submit.prevent="validateForm(formScope)"
        :data-vv-scope="formScope"
        :ref="formScope"
    >
        <Recipients
            :create="create"
            :formScope="formScope"
            :editorMode="editorMode"
            :countryCode="countryCode"
            :currentUiLanguage="currentUiLanguage"
            :forcedLanguage="forcedLanguage"
            @help-mode="$emit('help-mode', $event)"
        />
    </form>
</template>

<script>
import Recipients from './Recipients';

export default {
    name: 'Step5',
    components: {
        Recipients,
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        template: {
            type: Boolean,
            default: false,
        },
        formScope: {
            type: String,
            default: null
        },
        editorMode: {
            type: String,
            default: null,
        },
        currentUiLanguage: {
            type: String,
            default: null,
        },
        forcedLanguage: {
            type: String,
            default: null,
        },
    },
};
</script>
