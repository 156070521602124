<template>
    <v-card flat tile color="secondary" class="pb-3">
        <RowHeading
            :headline="$t('vvtProcessEditor.recipients.external.headline')"
            :subheadline="$t('vvtProcessEditor.recipients.external.subheadline')"
            :button-text="$t('vvtProcessEditor.recipients.external.add')"
            :disabled="!canEdit"
            @click:add="addRow"
            expanded
        />
        <div v-if="getCompanyGroupSettings('useVendor') && canEdit && missingRecipientVendors.length" class="ml-5 mr-0 mb-9">
            <v-alert type="info" text prominent icon="mdi-information-outline">
                <h3 class="text-h6" v-text="$tc('vvtProcessEditor.recipients.missingRecipientVendorsFromTools.headline', missingRecipientVendors.length)" />
                <div class="body-2 pa-0 pt-2 col-12 col-lg-10 col-xl-8" v-text="$tc('vvtProcessEditor.recipients.missingRecipientVendorsFromTools.text', missingRecipientVendors.length)" />
                <v-divider
                    class="my-4 info"
                    style="opacity: 0.22"
                ></v-divider>
                <GenericItemList
                    disableSorting
                    :items="vendorItems.filter(x => missingRecipientVendors.includes(x.id))"
                    :itemText="'name'"
                    dense
                    @select="createFromVendor($event)"
                    light
                    :customItem="{
                        property: 'id',
                        color: 'success',
                        icon: 'mdi-plus',
                        iconColor: 'success'
                    }"
                />
            </v-alert>
        </div>
        <div v-if="externalRecipients && externalRecipients.length" class="pl-6">
            <RecipientItem
                v-for="(recipient, i) in externalRecipients"
                :create="create"
                :key="recipient.id || recipient.clientId"
                :rowId="recipient.id || recipient.clientId"
                :formScope="formScope"
                :i="i"
                :ref="'externalRecipientItem_' + i"
                :lastIndex="externalRecipients.length - 1"
                :editorMode="editorMode"
                :countryCode="countryCode"
                :countryItems="countryItems"
                :groupsOfPersonGroupItems="groupsOfPersonGroupItems"
                :groupsOfPersonSelectedItems="groupsOfPersonSelectedItems"
                :currentUiLanguage="currentUiLanguage"
                :forcedLanguage="forcedLanguage"
                :isCollapsed="collapseStates[i]"
                :canEdit="canEdit"
                @collapse="onCollapse($event)"
                @collapseAll="onCollapseAll()"
                @help-mode="$emit('help-mode', $event)"
            />
        </div>
        <div v-else class="ma-3">
            <v-btn
                :disabled="!canEdit"
                @click="addRow"
                color="success"
                x-large
                block
                text
                outlined
                class="btn--justify-content-start text-transform--none"><v-icon left>mdi-plus</v-icon>{{ $t('vvtProcessEditor.recipients.external.addHint') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { kebabCase } from 'lodash';
const GenericItemList = () => import(/* webpackChunkName: "GenericItemList" */ '@/components/generic/list/ItemList.vue');
import RowHeading from '@/components/vvt/ProcessEditor/Row/Heading.vue';
import RecipientItem from './RecipientItem.vue';

export default {
    name: 'ExternalRecipientsIndex',
    components: {
        RowHeading,
        GenericItemList,
        RecipientItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        editorMode: {
            type: String,
            default: null
        },
        currentUiLanguage: {
            type: String,
            default: null
        },
        forcedLanguage: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            name: kebabCase(this.$options.name),
            collapseStates: [],
        };
    },
    computed: {
        ...mapGetters({
            groupsOfPersonGroupItems: 'groupsOfPersons/getItems',
            countryItems: 'countries/getItems',
            get: 'processingActivityModel/getProperty',
            getDetailedSaasToolsWithVendor: 'tools/getDetailedSaasToolsWithVendor',
            vendorItems: 'vendor/getItems',
            getCompanyGroupSettings: 'companyGroupSettings/get',
        }),
        externalRecipients() {
            return this.get('externalRecipients');
        },
        groupsOfPersonSelectedItems() {
            return this.get('groupsOfPersons');
        },
        canEdit () {
            return this.get('canEdit');
        },
        usedVendorIdsByTool() {
            return this.getDetailedSaasToolsWithVendor.map(x => x.vendorId).filter((v, i, a) => a.indexOf(v) === i);
        },
        missingRecipientVendors() {
            var missingRecipientVendors = this.usedVendorIdsByTool;

            this.usedVendorIdsByTool.forEach(vendorId => {
                if(this.externalRecipients.find(x => x.vendor === vendorId)){
                    missingRecipientVendors = missingRecipientVendors.filter(id => id !== vendorId);
                }
            });

            return missingRecipientVendors;
        }
    },
    methods: {
        async addRow() {
            this.$store
                .dispatch('processingActivityModel/addExternalRecipient')
                .then(() => {
                    this.collapseAll();
                    this.$set(this.collapseStates, 0, false);
                });
        },
        createFromVendor(vendorId) {
            this.addRow().then(() => {
                this.externalRecipients[0].vendor = vendorId;                
                this.$nextTick().then(() => {
                    const ref = this.$refs['externalRecipientItem_0'][0];
                    ref.loadVendorData();
                })
            });
        },
        onCollapse(event) {
            if (event.type === 'hide') {
                this.$set(this.collapseStates, event.index, true);
            } else {
                this.$set(this.collapseStates, event.index, false);
            }
        },
        onCollapseAll() {
            this.collapseAll();
        },
        collapseAll() {
            for (
                var i = 0, len = this.externalRecipients.length;
                i < len;
                i++
            ) {
                this.$set(this.collapseStates, i, true);
            }
        }
    },
    created() {
        this.collapseAll();
    },
};
</script>
