<template>
    <v-container fluid class="pl-1 pt-0 pb-3 mr-n1" :class="{'mb-3': !isCollapsed && lastIndex !== i, 'mt-3': !isCollapsed && i > 0}">
        <v-row class="mb-1 elevation-1">
            <v-toolbar short flat color="tertiary" class="cursor-pointer"
                @click="collapseItem(isCollapsed ? 'show' : 'hide', i)"
            >
                <v-toolbar-title class="pl-0 ml-n2 text-subtitle-1">
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-if="isCollapsed" v-text="'mdi-unfold-more-horizontal'" />
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-else v-text="'mdi-unfold-less-horizontal'" />
                    <span
                        class="v-toolbar-title-text pl-2"
                        :class="{
                            'error--text': hasError,
                        }"
                        >{{ $t('vvtProcessEditor.recipients.internal.headlineItem') }}
                        {{ i + 1 }}
                        <template v-if="groupName">: {{ groupName }}</template>
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip v-if="hasError" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="error" class="ma-2"
                            >warning</v-icon
                        >
                    </template>
                    <span v-html="$t('error')"></span>
                </v-tooltip>
                <v-tooltip v-if="i > 0" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('up')"
                        >
                            <v-icon>keyboard_arrow_up</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveUp'" />
                </v-tooltip>
                <v-tooltip v-if="i < lastIndex" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('down')"
                        >
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveDown'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="primary"
                            @click.stop="commentMode = true"
                            v-on="on"
                            small
                            fab
                            depressed
                            :outlined="!hasComments"
                            :disabled="!hasComments && !canEdit"
                        >
                            <v-icon
                                small
                                :color="hasComments ? 'white' : 'primary'"
                                >mdi-comment-outline</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'comments.buttonLabel'"></span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            class="mx-2"
                            color="success"
                            @click.stop="duplicateItem()"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>js-icon-copy</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'duplicate'" />
                </v-tooltip>
                <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            @click.stop="removeItemConfirmation = true"
                            class="mx-2"
                            color="error"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>clear</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'remove'" />
                </v-tooltip>
            </v-toolbar>
        </v-row>
        <v-row
            v-show="!isCollapsed"
            style="margin-top:-16px;"
            class="tertiary px-2 mb-1 fill-height elevation-1"
            align="stretch"
            justify="space-around"
        >
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.internal.typeOfRecipient.headline') }}
            </v-col>
            <v-col cols="12" :md="hasDepartmentInTypeOfRecipient ? 6 : 12">
                <LeaDropdown
                    :type="$getDropdownTypeFor('internalRecipientCategory', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                    return-object
                    :disabled="!canEdit"
                    v-model="typeOfRecipientModel"
                    :items="filteredTypeOfInternalRecipientItems"
                    :formScope="formScope"
                    :step="5"
                    :rowId="rowId"
                    fieldName="internalRecipient"
                    childFieldName="typeOfRecipient"
                    :label="$t('vvtProcessEditor.recipients.internal.typeOfRecipient.label')"
                    :hint="$t('vvtProcessEditor.recipients.internal.typeOfRecipient.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.internal.typeOfRecipient.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    chips
                    deletableChips
                    :delimiters="[',']"
                    :freetext-enabled-hint="$t('createItemHintComma')"
                />
            </v-col>
            <v-col cols="12" md="6" v-if="hasDepartmentInTypeOfRecipient">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    v-model="departmentModel"
                    :items="departmentItems"
                    :formScope="formScope"
                    :step="5"
                    :rowId="rowId"
                    fieldName="internalRecipient"
                    childFieldName="department"
                    :label="$t('vvtProcessEditor.recipients.internal.department.label')"
                    :hint="$t('vvtProcessEditor.recipients.internal.department.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.internal.department.helpMode')"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    chips
                    deletableChips
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                {{ $t('vvtProcessEditor.recipients.groupOfPersons.headline') }}
            </v-col>
            <v-col cols="12">
                <BaseInputMultiOption
                    :disabled="!canEdit"
                    fieldName="internalRecipient"
                    childFieldName="groupOfPersons"
                    :label="$t('vvtProcessEditor.recipients.groupOfPersons.label')"
                    :hint="$t('vvtProcessEditor.recipients.groupOfPersons.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.groupOfPersons.helpMode')"
                    :noItemsMessage="$t('vvtProcessEditor.groupOfPerson.groupOfPersons.noItemsMessage')"
                    :items="filteredGroupOfPersonItems"
                    v-model="groupsOfPersonModel"
                    :rowId="rowId"
                    :formScope="formScope"
                    :step="5"
                    multiple
                    @help-mode="$emit('help-mode', $event)"
                    @input="createRecipientPersonalData($event)"
                    :noDataText="$t('vvtProcessEditor.recipients.groupOfPersons.noDataText')"
                />
            </v-col>
            <v-col cols="12" v-if="usePersonalData && recipient.groupsOfPersons && recipient.groupsOfPersons.length">
                <RecipientPersonalData
                    :disabled="!canEdit"
                    :i="i"
                    :rowId="rowId"
                    :groupsOfPersonItems="groupsOfPersonGroupItems"
                    :personalDataItems="personalDataItems"
                    :groupsOfPersonSelectedItems="groupsOfPersonSelectedItems"
                    v-model="personalDataModel"
                    fieldName="internalRecipient"
                    childFieldName="personalData"
                    :label="$t('vvtProcessEditor.recipients.personalData.label')"
                    :dialogTitle="$t('vvtProcessEditor.recipients.personalData.dialogTitle')"
                    :dialogDescription="$t('vvtProcessEditor.recipients.personalData.dialogDescription')"
                />
            </v-col>
            <v-col cols="12" v-if="useDataCategories && recipient.groupsOfPersons && recipient.groupsOfPersons.length">
                <LeaDropdown
                    type="autocomplete"
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="5"
                    :i="i"
                    :rowId="rowId"
                    :items="usedPersonalDataCategoryItems"
                    v-model="personalDataCategoryModel"
                    fieldName="internalRecipient"
                    childFieldName="personalDataCategories"
                    @help-mode="$emit('help-mode', $event)"
                    multiple
                    :label="$t('vvtProcessEditor.recipients.personalDataCategories.label')"
                    :hint="$t('vvtProcessEditor.recipients.personalDataCategories.hint')"
                    :helpModeText="$t('vvtProcessEditor.recipients.personalDataCategories.helpMode')"
                    clearable
                    chips
                    deletableChips
                />
            </v-col>
        </v-row>
        <BaseConfirmationDialog
            v-model="removeItemConfirmation"
            :title="$t('rowItem.removeConfirmation.headline')"
            :text="$t('rowItem.removeConfirmation.text')"
            :confirmationText="$t('rowItem.removeConfirmation.delete')"
            :cancelText="$t('rowItem.removeConfirmation.cancel')"
            @cancel="removeItemConfirmation = false"
            @confirm="removeItem()"
        />
        <CommentsDialog
            v-model="commentMode"
            :generalComments="generalComments"
            :internalComments="internalComments"
            @data="setCommentsToModel($event)"
            :disabled="!canEdit"
        />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModelMixin from '@/components/vvt/ModelMixin';
import LeaDropdown from '@/components/Input/LeaDropdown';
import PersonalDataMixin from '../PersonalDataMixin.js';
import RecipientPersonalData from '../RecipientPersonalData.vue';
import CommentsDialog from '@/components/vvt/ProcessEditor/CommentsDialog.vue';

export default {
    name: 'InternalRecipientItem',
    mixins: [ModelMixin, PersonalDataMixin],
    components: {
        LeaDropdown,
        RecipientPersonalData,
        CommentsDialog,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        i: {
            type: Number,
            default: null
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        lastIndex: {
            type: Number,
            default: null
        },
        departmentItems: {
            type: Array,
            default: () => []
        },
        groupsOfPersonGroupItems: {
            type: Array,
            default: () => []
        },
        groupsOfPersonSelectedItems: {
            type: Array,
            default: () => []
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            highlightCollapseButton: false,
            removeItemConfirmation: false,
            commentMode: false,
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getChild: 'processingActivityModel/getChildProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            usedPersonalDataCategoryItems: 'processingActivityModel/getUsedPersonalDataCategoryItems',
            personalDataItems: 'personalData/getItems',
            typeOfInternalRecipientItems: 'internalRecipientCategories/getItems',
            getAllChildModelItems: 'processingActivityModel/getAllChildModelItems',
        }),
        filteredTypeOfInternalRecipientItems() {
            return this.typeOfInternalRecipientItems.filter(
                x =>
                    !x.isHidden ||
                    this.getAllChildModelItems({
                        prop: 'internalRecipients',
                        childProp: 'typeOfRecipient',
                    }).includes(x.id)
            );
        },
        recipient: {
            get() {
                return this.getChild({
                    property: 'internalRecipients',
                    child: this.i,
                });
            },
            set(val) {
                this.set({
                    property: 'internalRecipients',
                    child: this.i,
                    data: val,
                });
            },
        },
        typeOfRecipientModel: {
            get() {
                
                return this.getValue(this.filteredTypeOfInternalRecipientItems, this.recipient.typeOfRecipient);
            },
            set(val) {
                const data = this.setValue(
                    this.filteredTypeOfInternalRecipientItems,
                    val,
                    null,
                    null,
                    'internalRecipientCategories/unshiftItems'
                );
                const merged = {
                    ...this.recipient,
                    ...{ typeOfRecipient: data },
                };
                this.recipient = merged;
            },
        },
        groupName() {
            const arrGroupName = [];
            for (
                var i = 0, len = this.typeOfRecipientModel.length;
                i < len;
                i++
            ) {
                if (!this.typeOfRecipientModel[i]) {
                    continue;
                }
                if (
                    this.typeOfRecipientModel[i].title &&
                    this.typeOfRecipientModel[i].title !== ''
                ) {
                    arrGroupName.push(this.typeOfRecipientModel[i].title);
                } else {
                    const itemId =
                        typeof this.typeOfRecipientModel[i] === 'object'
                            ? this.typeOfRecipientModel[i].id
                            : this.typeOfRecipientModel[i];
                    const item = this.filteredTypeOfInternalRecipientItems.find(
                        x => x.id === itemId
                    );
                    if (item && item.title) {
                        arrGroupName.push(item.title);
                    }
                }
            }
            return arrGroupName.join(', ');
        },
        departmentModel: {
            get() {
                return this.getValue(this.departmentItems, this.recipient.department);
            },
            set(val) {
                const data = this.setValue(this.departmentItems, val);
                const merged = { ...this.recipient, ...{ department: data } };
                this.recipient = merged;
            },
        },
        filteredGroupOfPersonItems() {
            const items = this.get('groupsOfPersons').map(x => {
                const itemId = x.group.id || x.group.clientId;
                const rowId = x.id;
                const rowClientId = x.clientId;
                const originalItem = itemId ? this.groupsOfPersonGroupItems.find(
                    x => x.id === itemId || x.clientId === itemId
                ) : {title: this.$t('N/A')};
                const item = {
                    ...originalItem,
                    ...{ id: rowId, clientId: rowClientId },
                };
                return item;
            });
            return JSON.parse(JSON.stringify(items));
        },
        groupsOfPersonModel: {
            get() {
                return this.recipient.groupsOfPersons;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ groupsOfPersons: val },
                };
                this.recipient = merged;
            },
        },
        personalDataModel: {
            get() {
                return this.recipient.personalData;
            },
            set(val) {
                const merged = { ...this.recipient, ...{ personalData: val } };
                this.recipient = merged;
            },
        },
        personalDataCategoryModel: {
            get() {
                return this.recipient.personalDataCategory;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ personalDataCategory: val },
                };
                this.recipient = merged;
            },
        },
        generalComments: {
            get() {
                return this.recipient.generalComments;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ generalComments: val },
                };
                this.recipient = merged;
            },
        },
        internalComments: {
            get() {
                return this.recipient.internalComments;
            },
            set(val) {
                const merged = {
                    ...this.recipient,
                    ...{ internalComments: val },
                };
                this.recipient = merged;
            },
        },
        hasComments() {
            return (
                !!(
                    (this.generalComments &&
                        this.generalComments.trim().length) ||
                    (this.internalComments &&
                        this.internalComments.trim().length)
                ) === true
            );
        },
        hasDepartmentInTypeOfRecipient() {
            return this.recipient.typeOfRecipient.some(x => {
                const id = x.id ? x.id : x;
                const clientId = x.clientId ? x.clientId : x;
                const item = this.typeOfInternalRecipientItems.find(
                    d => (d.id === id || d.clientId === clientId)
                );
                if (item && item.isDepartment) {
                    return true;
                }
                if (this.departmentModel && this.departmentModel.length) {
                    return true;
                }
                return false;
            });
        },
        hasError() {
            // if (this.$validator.errors.has('internalRecipientType' + this.rowId, this.formScope)) {
            //   return true
            // }
            return false;
        },
        useDataCategories () {
            if (this.hasPersonalDataCategories) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataCategories');
        },
        usePersonalData () {
            if (this.hasPersonalData) {
                return true;
            }
            return this.getCompanyGroupSettings('useDataFields');
        },
        hasPersonalData() {
            return this.get('groupsOfPersons').some(x => x.personalData && x.personalData.length);
        },
        hasPersonalDataCategories() {
            return this.get('groupsOfPersons').some(x => x.personalDataCategory && x.personalDataCategory.length);
        }
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setChildProperty',
            remove: 'processingActivityModel/removeInternalRecipient',
            add: 'processingActivityModel/addInternalRecipient',
            addPersonalData:
                'processingActivityModel/addInternalRecipientPersonalData',
            move: 'processingActivityModel/moveInternalRecipient',
        }),
        removeItem() {
            this.remove(this.i).then(this.$emit('collapseAll'));
        },
        duplicateItem() {
            this.add({
                item: this.recipient,
                index: this.i + 1,
            })
                .then(this.$emit('collapseAll'))
                .then(this.collapseItem('show', this.i + 1));
        },
        moveItem(dir) {
            this.move({
                from: this.i,
                to: dir === 'up' ? this.i - 1 : this.i + 1,
            }).then(this.$emit('collapseAll'));
        },
        collapseItem(type, index) {
            this.$emit('collapse', {
                type: type,
                index: index,
            });
        },
        setCommentsToModel(val) {
            this.generalComments = val.generalComments;
            this.internalComments = val.internalComments;
        }
    },
    created() {
        if (!this.recipient.personalData) {
            this.addPersonalData(this.i);
        }
    }
};
</script>
